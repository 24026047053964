/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    debugger;
    state.Citys.unshift(item)
  },
  SET_Citys(state, Citys) {
    debugger;
    try {
    
      state.Citys = Citys;
     
    } catch (error) {
      console.error("Failed to parse Citys data JSON:", error);
      state.Citys = [];
    }
  },
  UPDATE_City(state, City) {
    debugger
    const CityIndex = state.Citys.findIndex((p) => p.Id == City.Id)
    Object.assign(state.Citys[CityIndex], City)
  },
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.Citys.findIndex((p) => p.ID == itemId)
      state.Citys.splice(ItemIndex, 1)
  },
}
